import { useStoreState } from 'easy-peasy'
import React, { useState } from 'react'
import { formatValueType } from '../helpers'
import { Spin, message } from 'antd'
import axios from 'Utils/axios'
import { LoadingOutlined } from '@ant-design/icons'

export default function DownloadSQL() {
  const { predictive_segment, predefined_segment, criteria_groups, store_ids } = useStoreState(
    (state: any) => state.customerSegments
  )
  const [isLoading, setIsLoading] = useState(false)
  const critGroupsContainAudience = criteria_groups?.find(
    (item) =>
      item?.dimension?.segments[0]?.audience_Type === 'audience' ||
      item?.dimension?.segments[0]?.audience_Type === 'customer' ||
      item?.dimension?.segments[0]?.audience_Type === 'visitor'
  )
  const temp_criteria_groups = criteria_groups.map((group) => {
    return {
      condition: group.condition,
      dimension: {
        condition: group.dimension.condition,
        segments: group.dimension.segments.map((segment) => {
          let object
          try {
            object = {
              ...segment,
              value:
                ['IN', 'NOT_IN', 'IN_THE_FOLLOWING', 'NOT_IN_THE_FOLLOWING'].includes(
                  segment?.operator
                ) && segment?.type !== 'SEGMENT'
                  ? JSON?.parse(segment?.value)
                      ?.map((item, index) =>
                        index !== JSON?.parse(segment?.value)?.length - 1 ? `${item};` : item
                      )
                      ?.join('')
                  : JSON.parse(segment.value.toString())[0] || segment.value.toString(),
              time_operator: segment?.time_operator?.name || segment?.time_operator,
              time_value: formatValueType(segment.time_value),
            }
            return object
          } catch {
            object = {
              ...segment,
              value: segment?.value,
              time_operator: segment?.time_operator?.name || segment?.time_operator,
              time_value: formatValueType(segment.time_value),
            }
            return object
          }
        }),
      },
    }
  })

  const handleDownloadSQL = async () => {
    setIsLoading(true)
    try {
      const resp = await axios.post('/audience-dimensions/dimensions/build', PAYLOAD)
      const element = document.createElement('a')
      const file = new Blob([resp.data?.query], { type: 'text/plain' })
      element.href = URL.createObjectURL(file)
      element.download = 'kariba_exported_sql.txt'
      document.body.appendChild(element)
      element.click()
      message.success('Export successfully')
    } catch {
      message.error('Cannot export SQL')
    }
    setIsLoading(false)
  }

  const PAYLOAD = {
    store_ids: JSON.stringify(store_ids),
    predictive_segment: predictive_segment || null,
    predefined_segment: predefined_segment || null,
    dimensions: criteria_groups[0]?.dimension?.segments.length
      ? JSON.stringify(temp_criteria_groups)
      : !predictive_segment && !predefined_segment
      ? '[]'
      : null,
    segmentation_type: critGroupsContainAudience?.dimension?.segments[0]?.audience_Type || null,
  }

  if (isLoading)
    return <Spin indicator={<LoadingOutlined style={{ fontSize: 24, width: 100 }} spin />} />

  return (
    <div
      style={{
        color: 'var(--brand_primary)',
        fontSize: 14,
        cursor: 'pointer',
        fontWeight: 600,
      }}
      onClick={handleDownloadSQL}
    >
      Export SQL
    </div>
  )
}
