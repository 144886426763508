import React from 'react'
import { Typography, Tooltip } from 'antd'
import moment from 'moment'

import { CampaignStatus } from 'CampaignTypes'
import { systemColor } from 'DesignSystem/Colors'
import { capitalizeFirstLetter } from 'Utils'

const { Text } = Typography
export const STATUS_COLORS = {
  init: 'greekBlue',
  waiting: 'gold',
  running: 'green',
  completed: 'blue',
  deleted: 'red',
  error: 'red',
}

const format = (values) => {
  return values?.map((value) => {
    return (
      <div
        style={{ width: 'fit-content', display: 'inline-block', textAlign: 'left' }}
        className={`mr-2 mb-2 font-semibold rounded-sm text-sm py-1 px-2 bg-${
          value?.color === 'geekblue' ? 'greekBlue' : value?.color
        }_1 text-${value?.color === 'geekblue' ? 'greekBlue' : value?.color}_6`}
      >
        {value?.name}
      </div>
    )
  })
}

export const DEFAULT_COLUMNS = [
  {
    title: 'ID',
    dataIndex: 'id',
    width: 100,
    render: (value) => {
      if (value) return value
      else return 'N/A'
    },
  },
  {
    title: 'Campaign',
    dataIndex: 'name',
    sorter: true,
    align: 'left',
    render: (campaign) =>
      campaign ? (
        <Tooltip title={campaign}>
          <Text className="campaign-title" strong>
            {campaign}
          </Text>
        </Tooltip>
      ) : (
        'N/A'
      ),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: 150,
    sorter: true,
    render: (status) =>
      status ? (
        <span
          className="font-semibold rounded-sm text-sm py-1 px-2"
          style={{
            width: 'fit-content',
            display: 'inline-block',
            textAlign: 'left',
            color: `${
              STATUS_COLORS[status] === systemColor.geekblue_6
                ? systemColor.geekblue_6
                : systemColor[STATUS_COLORS[status] + '_6']
            }`,
            backgroundColor: `${
              STATUS_COLORS[status] === systemColor.geekblue_1
                ? systemColor.greekBlue_1
                : systemColor[STATUS_COLORS[status] + '_1']
            }`,
          }}
        >
          {capitalizeFirstLetter(status)}
        </span>
      ) : (
        'N/A'
      ),
  },
]

export const STATUS: { label: string; value: CampaignStatus }[] = [
  { label: 'All', value: '' },
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
  { label: 'Draft', value: 'draft' },
]
export const IS_ACTIVE = [
  { label: 'Published', value: 'published' },
  { label: 'Draft', value: 'draft' },
]
export const CHANNELS = [
  // { label: 'Community', value: 'community' },
  { label: 'Website', value: 'website' },
  { label: 'Email', value: 'email' },
  { label: 'SMS', value: 'sms' },
]
export const PLACEMENTS = [
  // { label: 'Harasocial - SF', value: 'hrs_store_farming' },
  { label: 'Manual Targeted Push', value: 'web_push' },
  { label: 'Email', value: 'email' },
  // { label: 'Kaipass', value: 'kaipass' },
  // { label: 'Harasocial', value: 'harasocial' },
  { label: 'SMS', value: 'sms' },
  // { label: 'Zalo', value: 'zalo' },
  // { label: 'Post engagement', value: 'post_engagement' },
  // { label: 'Link Clicks', value: 'link_clicks' },
]
export const DATE = [
  { label: 'Published', value: 'published' },
  { label: 'Draft', value: 'draft' },
]
