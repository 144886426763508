import React from 'react'
import { Typography, Tooltip, Switch } from 'antd'
import StatusTag from 'Components/StatusTag'
import moment from 'moment'
import { capitalizeFirstLetter } from 'Utils'
import InUsedCampaign from 'Pages/Audiences/InUsedCampaign'
import Switcher from '../Detail/Components/Swicher'

const { Text } = Typography

export const STATUS = [
  { label: 'Published', value: 'published' },
  { label: 'Draft', value: 'draft' },
  { label: 'Creating', value: 'creating' },
  { label: 'Updating', value: 'updating' },
  { label: 'All', value: '' },
]

export const SOURCE = [
  { label: 'Kariba', value: 'kariba' },
  // { label: 'Facebook', value: 'facebook' },
]

export const USED_IN = [
  { label: 'Active campaign', value: 'active' },
  { label: 'Inactive campaign', value: 'inactive' },
  { label: 'Not in use', value: 'not_in_use' },
]

export const FILTER_TABS = (creators, filters, setFilters) => {
  return [
    {
      label: 'Status',
      value: 'segment_status',
      options: STATUS,
      type: 'TAB' as const,
      onClick: ({ key, value }) => setFilters({ ...filters, [key]: value }),
    },
    {
      label: 'User source',
      value: 'user_source',
      options: SOURCE,
      type: 'DROPDOWN' as const,
      onClick: ({ key, value }) => setFilters({ ...filters, [key]: value }),
    },
    {
      label: 'Used in',
      value: 'used_in',
      options: USED_IN,
      type: 'DROPDOWN' as const,
      onClick: ({ key, value }) => setFilters({ ...filters, [key]: value }),
    },
    {
      label: 'Created by',
      value: 'created_by',
      options: creators,
      type: 'DROPDOWN' as const,
      onClick: ({ key, value }) => setFilters({ ...filters, [key]: value }),
    },
  ]
}

export function calculateTimeDifference(inputTime) {
  if (!inputTime) return ''
  // Get current time in Vietnam timezone
  const currentTimeVietnam = moment().tz('Asia/Ho_Chi_Minh')

  // Parse input time and convert it to Vietnam timezone
  const parsedInputTime = moment(inputTime).tz('Asia/Ho_Chi_Minh')

  // Check if the parsed input time is valid
  if (!parsedInputTime.isValid()) {
    return '' // Return empty string if input time is invalid
  }

  // Calculate the time difference in milliseconds
  const diffInMilliseconds = currentTimeVietnam.diff(parsedInputTime)

  // Convert the difference to duration for detailed formatting
  const duration = moment.duration(diffInMilliseconds)

  // Handle NaN results
  if (isNaN(duration.asMilliseconds())) {
    return '' // Return empty string if the difference is NaN
  }

  if (duration.asHours() < 1) {
    // Less than 1 hour, return minutes
    const minutes = Math.floor(duration.asMinutes())
    return `${minutes} mins`
  } else if (duration.asHours() >= 24) {
    // More than 24 hours, return days and hours
    const days = Math.floor(duration.asDays())
    const hours = Math.floor(duration.asHours() % 24)
    return `${days} ds ${hours} hrs`
  } else {
    // Between 1 hour and 24 hours, return hours and minutes
    const hours = Math.floor(duration.asHours())
    const minutes = Math.floor(duration.asMinutes() % 60)
    return `${hours} hrs ${minutes} mins`
  }
}
