import { Tooltip, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { DEFAULT_COLUMNS, STATUS_COLORS } from './constants'
import { systemColor } from 'DesignSystem/Colors'
import { capitalizeFirstLetter } from 'Utils'
import moment from 'moment'
import axios from 'Utils/axios'
import queryString from 'query-string'
import _ from 'lodash'

export interface ICampaignMetrics {
  campaign_id?: number
  analytic_key_metrics?: AnalyticKeyMetrics
}

export interface AnalyticKeyMetrics {
  impression?: number
  revenue?: number
  cost?: number
  open?: number
  click?: number
  cr?: number
  cpc?: number
}

export default function useColumns() {
  const [data, setData] = useState([])
  const [campaignIds, setCampaignIds] = useState<number[]>([])
  const [columns, setColumns] = useState<unknown[]>(DEFAULT_COLUMNS)
  const [loading, setLoading] = useState(true)
  const fetchMetrics = async (campaignIds: number[]) => {
    try {
      setLoading(true)
      const resp = await axios.get<ICampaignMetrics[]>('/campaigns/metrics', {
        params: {
          campaign_ids: campaignIds,
        },
        paramsSerializer: (p) => {
          return queryString.stringify(p, { arrayFormat: 'comma' })
        },
      })

      setColumns([
        ...DEFAULT_COLUMNS,
        {
          title: 'Views',
          dataIndex: 'id',
          width: 100,
          render: (value) => {
            const metric = resp.data?.find((item) => item.campaign_id === value)
              ?.analytic_key_metrics?.impression
            return metric !== undefined ? _.round(metric || 0, 2)?.toLocaleString() : 'N/A'
          },
        },
        {
          title: 'CPC',
          dataIndex: 'id',
          width: 100,
          render: (value) => {
            const metric = resp.data?.find((item) => item.campaign_id === value)
              ?.analytic_key_metrics?.cpc
            return metric !== undefined ? _.round(metric || 0, 2)?.toLocaleString() : '0'
          },
        },
        {
          title: 'Clicks',
          dataIndex: 'id',
          width: 100,
          render: (value) => {
            const metric = resp.data?.find((item) => item.campaign_id === value)
              ?.analytic_key_metrics?.click
            return metric !== undefined ? _.round(metric || 0, 2)?.toLocaleString() : 'N/A'
          },
        },
        {
          title: 'CTR',
          dataIndex: 'id',
          width: 100,
          render: (value) => {
            const metric = resp.data?.find((item) => item.campaign_id === value)
              ?.analytic_key_metrics?.cr
            return metric ? `${_.round(metric, 2)}%` : 'N/A'
          },
        },
        {
          title: 'Updated on',
          dataIndex: 'updated_at',
          sorter: true,
          render: (text) => (text ? moment(text).format('DD-MM-YYYY') : 'N/A'),
          width: 150,
        },
      ])
      setLoading(false)
    } catch (err) {}
  }

  useEffect(() => {
    if (campaignIds?.length) fetchMetrics(campaignIds)
  }, [campaignIds])

  useEffect(() => {
    const _campaignIds = data?.map((item: any) => item.id) || []
    if (!_.isEqual(_campaignIds, campaignIds)) {
      setCampaignIds(_campaignIds)
    }
  }, [data])

  return {
    loading,
    columns,
    setData,
  }
}
