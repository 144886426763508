import React, { useEffect, useState } from 'react'
import { Tree, Select } from 'antd'
import { useStoreState } from 'easy-peasy'

import { DataNode } from 'rc-tree/lib/interface'
import { getStores } from 'Services/customer-segments'
import { formatErrors } from 'Utils'
import { searchText as searchTextUtil } from 'Utils'
export interface StoreNode extends DataNode {
  store_code?: string
  storeKey?: string
}

const changeKeyValue = (list, keyNeedChange, keyToGet) => {
  if (list[keyToGet]) {
    let temp = list[keyToGet]
    list.storeKey = list.key
    list[keyNeedChange] = temp
    return list
  }

  return {
    ...list,
    children: list.children.map((item) => {
      return changeKeyValue(item, keyNeedChange, keyToGet)
    }),
  }
}

const Stores = ({ onChange, value }) => {
  const { predefined_segment, store_ids } = useStoreState((state) => state.customerSegments)
  const [data, setData] = useState<StoreNode[] | any>([])
  const [originalData, setOriginalData] = useState<StoreNode[]>([])
  const [searchText, setSearchText] = useState('')
  const [expandKeys, setExpandKeys] = useState([])
  const [checkedKeys, setCheckedKeys] = useState<string[]>([])
  const [errors, setErrors] = useState('')

  const convertKey = (tree, ids) => {
    const tempArray: any = []
    for (let i = 0; i < tree?.length; i++) {
      for (let j = 0; j < tree[i].children?.length; j++) {
        for (let k = 0; k < tree[i].children[j].children?.length; k++) {
          if (ids.includes(tree[i].children[j].children[k].storeKey)) {
            tempArray.push(tree[i].children[j].children[k].key)
          }
        }
      }
    }
    return tempArray
  }

  useEffect(() => {
    setCheckedKeys(value || [])
  }, [value])

  const fetchData = async () => {
    try {
      const resp = await getStores()
      setErrors('')
      const changedKeyData = resp.data.tree_store.map((region) =>
        changeKeyValue(region, 'key', 'store_code')
      )
      setData([...changedKeyData])
      setOriginalData([...changedKeyData])
      if (data) {
        const keyArray = convertKey(resp.data.tree_store, store_ids)
        setCheckedKeys(keyArray)
      }
    } catch (err: any) {
      setErrors(formatErrors(err.response?.data) || 'Something went wrong')
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const computeDate = (text) => {
    return originalData.reduce((accum: any, area: any) => {
      // Zones
      const zones = area.children
        .map((zone: any) => {
          // Stores
          const stores = zone.children
            .filter((store) => {
              const matchedCode = searchTextUtil(store.store_code, text)
              const matchedTitle = searchTextUtil(store.title, text)
              if (matchedCode || matchedTitle) return true
            })
            .filter((x) => x)

          if (stores.length) {
            return { ...zone, children: stores }
          } else return false
        })
        .filter((x) => x)
      if (zones.length) accum.push({ ...area, children: zones })
      return accum
    }, [])
  }

  React.useMemo(() => {
    if (!searchText) return setData(originalData)
    const newData = computeDate(searchText)
    setData(newData)
  }, [searchText])

  return (
    <div>
      <Select
        showSearch
        disabled={predefined_segment}
        onSearch={setSearchText}
        searchValue={searchText}
        value={store_ids?.length ? `${checkedKeys?.length} stores` : undefined}
        dropdownStyle={{
          maxHeight: 400,
          overflow: 'scroll',
        }}
        dropdownRender={() => (
          <Tree
            checkedKeys={checkedKeys}
            checkable
            onCheck={(keys, nodes) => {
              console.log(nodes, checkedKeys)
              if (searchText) {
                onChange({
                  ...nodes,
                  checkedNodes: [nodes?.checkedNodes?.[0], ...checkedKeys?.map((key) => ({ key }))],
                })
                return
              }
              onChange(nodes)
            }}
            treeData={data}
          />
        )}
        className={`w-full segment-store-select ${errors && 'errors'}`}
        placeholder="Select"
      />
      {errors && <div className="text-negative">{errors}</div>}
    </div>
  )
}

export default Stores
